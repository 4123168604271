import React from 'react'
import KopCity from '../assets/images/services/banner1.jpg'
import Header from "./Header";
import Footer from "./Footer";

function Services() {
    return (
        <div className="bodyWrapper">
            <Header />
            <section className="bannerImg">
                <img src={KopCity} alt="about banner" />
            </section>
            <section className="pageContent">
                <div className="wrap">
                    <h1 className="pageHeading">Services</h1>
                    <div className="content">
                        {/* <h2>ISO 9001 : 2015 CERTIFIED COMPANY</h2> */}
                        <p>
                        <strong>Samarth Security Systems (India) Pvt Ltd</strong> gives you access to the most advanced security technology products & solutions with 24/7/365 support covering PAN India, Middle-East, South-East Asia. We deliver the most reliable services anywhere – and everywhere – in the country and abroad to maximise up-time and life of your equipment.
                            </p>
                        <p>We understand that security is a critical component to any business, and we strive to provide our clients with the peace of mind they need to focus on growing their business. Whether it's through our state-of-the-art Surveillance systems or Access Control solutions, we always go above and beyond to ensure the reliability and effectiveness of our products. </p>
                        <p> We are here to help you – no matter what it takes. We offer on-site support and training, customized preventative maintenance programmes, and on-call support by our highly qualified and experienced technical team.</p>
                    </div>
                </div>
            </section>
            <Footer />
        </div>
    )
}

export default Services
