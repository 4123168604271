import React from "react";
import { useEffect, useState } from "react";
import KopCity from "../assets/images/clients/banner.jpg";
import Header from "./Header";
import Footer from "./Footer";
import axios from "axios";

function OurClients(props) {
  const [data, setData] = useState([]);

  useEffect(() => {
    axios.get("getClientDetails").then((res) => setData(res.data));
  }, []);

  return (
    <div className="bodyWrapper">
      <Header />
      <section className="bannerImg">
        <img src={KopCity} alt="about banner" />
      </section>
      <section className="pageContent">
        <div className="wrap">
          <h1 className="pageHeading">CLIENTLE</h1>
          <div className="content">
            {data.map((item) => (
              <>
                <h2 className="headStyle" key={item.id}>
                  {item.client_category}
                </h2>
                <ul className="clientList">
                  {item.clients.map((clients) => (
                    <img
                      key={clients.id}
                      src={
                        "https://api.samarthsec.com/public/admin/images/clients/" +
                        clients.clients_image
                      }
                      style={{
                        padding: "5px 15px",
                        width: "auto",
                        maxWidth: "250px",
                        maxHeight: "150px",
                      }} alt={item.client_category}
                    />
                  ))}
                </ul>
              </>
            ))}
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
}

export default OurClients;
