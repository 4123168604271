import { useEffect, useState } from "react";
import React from "react";
import axios from "axios";

function Awards() {
  const [data, setData] = useState([]);

  useEffect(() => {
    axios.get("getAwardsAndAccolades").then((res) => setData(res.data));
  }, []);

  return (
    <div>
      <section className="pageContent" style={{ padding: "40px 0 40px" }}>
        <div className="wrap">
          <h1 className="sectionTitle">AWARDS &amp; ACCOLADES </h1>
          <div className="content">
            <ul className="awardList">
              {data.map((item) => (
                <li key={item.id}>
                  <span className="imgContainer">
                    <img
                      src={
                        "https://api.samarthsec.com/public/admin/images/awards_and_accolades/" +
                        item.awards_and_accolades_image
                      }
                      alt={item.description}
                    />
                  </span>
                  <span className="imgText">
                    <p style={{ fontWeight: "500", color: "white" }}>
                      {" "}
                      {item.title}{" "}
                    </p>
                  </span>
                </li>
              ))}

            
            </ul>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Awards;
