import React from "react";
import { Button, Modal } from "react-bootstrap";
import Header from "./Header";
import Footer from "./Footer";
import NewsImg from "../assets/images/news/banner.jpg";
import axios from "axios";

class OurNews extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      img: "",
      Info: "",
    };

    this.state = {
      items: [],
    };
  }
  handleClick(img, info) {
    this.setState({ show: !this.state.show });
    this.setState({ img: img });
    this.setState({ Info: info });
  }

  // componentDidMount() {
  //     fetch("https://api.jan-kalyan.org/public/api/getNews")
  //         .then((res) => res.json())
  //         .then((json) => {
  //             this.setState({ items: json });
  //         })
  // }

  componentDidMount() {
    axios.get("getNews").then((response) => {
      console.log(response.data);
      this.setState({
        items: response.data,
      });
    });
  }

  render() {
    const { items } = this.state;
    return (
      <div className="bodyWrapper">
        <Modal
          style={{ textAlign: "center" }}
          show={this.state.show}
          onHide={() => this.handleClick()}
        >
          {/* <Modal.Header closeButton><h2>{this.state.HeadingTitle}</h2> </Modal.Header> */}
          <Modal.Body>
            <img src={this.state.img} alt="" style={{ width: "100%" }} />
          </Modal.Body>
          <Modal.Footer>
            <p style={{ fontSize: "16px", textAlign: "center" }}>
              {this.state.Info}
            </p>
            <Button
              style={{
                backgroundColor: "red",
                marginTop: "10px",
                padding: "5px",
                color: "#fff",
              }}
              onClick={() => this.handleClick()}
            >
              Close
            </Button>
          </Modal.Footer>
        </Modal>

        <Header />

        <section className="bannerImg">
          <img src={NewsImg} alt="Client banner" />
        </section>
        <div className="overlay"></div>

        <section className="pageContent">
          <div className="wrap">
            <h1 className="pageHeading">News</h1>
            <div className="content">
              <ul className="newsList">
                {this.state.items.map((item) => (
                  <li key={item.id} style={{ textAlign:"center" }}> 
                    <img
                      style={{ width: "174px", height:"140px" }}
                      src={
                        "https://api.samarthsec.com/public/admin/images/news/" +
                        item.news_image
                      }
                      alt={item.description}
                      onClick={() =>
                        this.handleClick(
                          "https://api.samarthsec.com/public/admin/images/news/" +
                            item.news_image,
                          item.description
                        )
                      } 
                    />
                    <p style={{ textAlign:"center" }}
                      onClick={() =>
                        this.handleClick(
                          "https://api.samarthsec.com/public/admin/images/news/" +
                            item.news_image,
                          item.description
                        )
                      }
                    >
                      {item.title}
                    </p>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </section>

        <Footer />
      </div>
    );
  }
}
export default OurNews;
