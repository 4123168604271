import React from "react";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import axios from "axios";

function Footer() {
  const [data, setData] = useState([]);

  useEffect(() => {
    axios
      .get("getProjectCategoryAndSubCategory")
      .then((res) => setData(res.data));
  }, []);

  return (
    <div className="kingster-body">
      <footer className=" ">
        <div
          className="kingster-footer-wrapper "
          style={{
            position: "relative",
            left: "50%",
            transform: "translate(-50%, 0)",
            padding: "30px 40px",
            backgroundColor: "#181818",
          }}
        >
          <div className="kingster-footer-container kingster-container clearfix">
            <div
              className="kingster-footer-column kingster-item-pdlr kingster-column-20"
              style={{ paddingTop: "7px" }}
            >
              <div id="text-2" className="widget widget_text kingster-widget">
                <div className="textwidget">
                  <h4 style={{ color: "#ffffff", fontSize: "20px" }}>
                  Samarth Security Systems (India) Pvt Ltd.
                  </h4>
                  <p>
                    <span
                      className="gdlr-core-space-shortcode"
                      style={{ marginTop: "5px" }}
                    ></span>
                  </p>
                  <br />
                  <p style={{ display: "flex", textAlign: "left" }}>
                    <span className="fa fa-map-marker"></span>
                    Office: Plot No. A-120, M.I.D.C. Khairane, TTC Industrial
                    Area, Thane-Belapur Road, Navi Mumbai, Maharashtra 400710
                  </p>
                  <p>
                    <br />
                    <span style={{ fontSize: "15px", color: "#ffffff" }}>
                      <span className="fa fa-phone"></span>
                      91-22-27622 754/59
                    </span>

                    <a
                      style={{ fontSize: "15px", color: "#ffffff" }}
                      href="mailto:sales@samarthsec.com"
                    >
                      <span className="fa fa-envelope"></span>
                      sales@samarthsec.com
                    </a>
                  </p>
                  <div className="gdlr-core-divider-item gdlr-core-divider-item-normal gdlr-core-left-align">
                    <div
                      className="gdlr-core-divider-line gdlr-core-skin-divider"
                      style={{ borderColor: "#3db166", borderWidth: "2px" }}
                    ></div>
                  </div>
                </div>
              </div>
            </div>
            <div className="kingster-footer-column kingster-item-pdlr kingster-column-12">
              <div
                id="gdlr-core-custom-menu-widget-4"
                className="widget widget_gdlr-core-custom-menu-widget kingster-widget"
              >
                <h3 className="kingster-widget-title">Quick Links</h3>
                <span className="clear"></span>
                <div className="menu-academics-container">
                  <ul
                    id="menu-academics"
                    className="gdlr-core-custom-menu-widget gdlr-core-menu-style-plain"
                  >
                    {/* <li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-5720 kingster-normal-menu">
                      <Link to="/Covid19Products" title="Covid-19 Products">
                        Covid-19 Products
                      </Link>
                    </li> */}
                    {/* <li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-5720 kingster-normal-menu">
                      <Link to="/Products" title="products">
                        Products
                      </Link>
                    </li> */}

                    <li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-5715 kingster-normal-menu">
                      <Link to="/OurClients" title="Clients">
                        Clients
                      </Link>
                    </li>
                    <li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-5716 kingster-normal-menu">
                      <Link to="/Services" title="Services">
                        Services
                      </Link>
                    </li>
                    <li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-5717 kingster-normal-menu">
                      <Link to="/OurNews" title="News">
                        News
                      </Link>
                    </li>
                    <li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-5717 kingster-normal-menu">
                      <Link to="/ContactUs" title="Contact Us">
                        Contact Us
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div
              className="kingster-footer-column kingster-item-pdlr kingster-column-20"
              style={{ width: "40%" }}
            >
              <div
                id="gdlr-core-custom-menu-widget-3"
                className="widget widget_gdlr-core-custom-menu-widget kingster-widget"
              >
                <h3 className="kingster-widget-title">Prime Project's</h3>
                <span className="clear"></span>
                <div
                  className="gdlr-core-pbf-wrapper "
                  style={{ padding: "0px 0px 0px 0px" }}
                >
                  <div className="gdlr-core-pbf-background-wrap"></div>
                  <div className="gdlr-core-pbf-wrapper-content gdlr-core-js ">
                    <div className="gdlr-core-pbf-wrapper-container clearfix gdlr-core-container">
                      <div className="gdlr-core-pbf-column gdlr-core-column-40 gdlr-core-column-first">
                        <div className="gdlr-core-pbf-column-content-margin gdlr-core-js ">
                          <div className="gdlr-core-pbf-column-content clearfix gdlr-core-js ">
                            <div className="gdlr-core-pbf-element">
                              <div className="menu-campus-life-container">
                                <ul
                                  id="menu-campus-life"
                                  className="gdlr-core-custom-menu-widget gdlr-core-menu-style-plain"
                                >
                                  {data.map((item) => (
                                    <>
                                      {item.id==1 && item.sub_category.map((sub_category) => (
                                        <li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-5721 kingster-normal-menu">
                                          <Link
                                            to={
                                              "/OurProjects/" + sub_category.id
                                            }
                                          >
                                            {sub_category.sub_category}
                                          </Link>
                                        </li>
                                      ))}
                                    </>
                                  ))}

                                  {/* <li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-5721 kingster-normal-menu">
                                    <Link to="/SafeCityKolhapur">
                                      Kolhapur safe city project
                                    </Link>
                                  </li>
                                  <li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-5721 kingster-normal-menu">
                                    <Link to="/SafeCityCidco">
                                      Cidco safe city project
                                    </Link>
                                  </li>
                                  <li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-5721 kingster-normal-menu">
                                    <Link to="/SafeCityNanded">
                                      Nanded safe city project
                                    </Link>
                                  </li>
                                  <li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-5721 kingster-normal-menu">
                                    <Link to="/SafeCityWardha">
                                      Wardha safe city project
                                    </Link>
                                  </li>
                                  <li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-5721 kingster-normal-menu">
                                    <Link to="/Vidhanbhavan">VidhanBhavan</Link>
                                  </li> */}
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="gdlr-core-pbf-column gdlr-core-column-20 gdlr-core-column-first">
                        <div className="gdlr-core-pbf-column-content-margin gdlr-core-js ">
                          <div className="gdlr-core-pbf-column-content clearfix gdlr-core-js ">
                            <div className="gdlr-core-pbf-element">
                              <div className="menu-campus-life-container">
                                <ul
                                  id="menu-campus-life-1"
                                  className="gdlr-core-custom-menu-widget gdlr-core-menu-style-plain"
                                >
                                    {data.map((item) => (
                                    <>
                                      {item.id==2 && item.sub_category.map((sub_category) => (
                                        <li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-5721 kingster-normal-menu">
                                          <Link
                                            to={
                                              "/OurProjects/" + sub_category.id
                                            }
                                          >
                                            {sub_category.sub_category}
                                          </Link>
                                        </li>
                                      ))}
                                    </>
                                  ))}

{/* 

                                  <li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-5721 kingster-normal-menu">
                                    <Link to="/MumbaiPort">
                                      Mumbai Port Trust
                                    </Link>
                                  </li>
                                  <li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-5721 kingster-normal-menu">
                                    <Link to="/NammaMetro">Namma Metro</Link>
                                  </li>
                                  <li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-5721 kingster-normal-menu">
                                    <Link to="/TataGroup">TATA Group</Link>
                                  </li>
                                  <li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-5721 kingster-normal-menu">
                                    <Link to="/ItcHotel">ITC Hotel Group</Link>
                                  </li> */}
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="kingster-copyright-wrapper"
          style={{ padding: "0px 40px" }}
        >
          <div className="kingster-copyright-container kingster-container clearfix">
            <div className="kingster-copyright-left kingster-item-pdlr">
              {" "}
              © Copyright 2021 Samarth Security Systems (India) Pvt Ltd. All
              Rights Reserved
            </div>
            <div className="kingster-copyright-right kingster-item-pdlr">
              <div
                className="gdlr-core-social-network-item gdlr-core-item-pdb  gdlr-core-none-align"
                style={{ paddingBottom: "0px" }}
              >
                <a
                  href="https://www.facebook.com/samarthsec/"
                  target="_blank"
                  className="gdlr-core-social-network-icon"
                  title="facebook"
                >
                  <i className="fa fa-facebook"></i>
                </a>
                <a
                  href="https://www.linkedin.com/company/samarthsec"
                  target="_blank"
                  className="gdlr-core-social-network-icon"
                  title="linkedin"
                >
                  <i className="fa fa-linkedin"></i>
                </a>
                <a
                  href="https://twitter.com/SamarthSec"
                  target="_blank"
                  className="gdlr-core-social-network-icon"
                  title="twitter"
                >
                  <i className="fa fa-twitter"></i>
                </a>
                <a
                  href="https://www.instagram.com/samarthsecuritysystems/?igshid=MzRlODBiNWFlZA%3D%3D"
                  target="_blank"
                  className="gdlr-core-social-network-icon"
                  title="instagram"
                >
                  <i className="fa fa-instagram"></i>
                </a>
                {/* <a
                  href="#"
                  target="_blank"
                  className="gdlr-core-social-network-icon"
                  title="youtube"
                >
                  <i className="fa fa-youtube-play"></i>
                </a> */}
              </div>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
}

export default Footer;
