import React from "react";
import { Link } from "react-router-dom";
import Logo from "../assets/images/smarthsec-logo.png";
import { useEffect, useState } from "react";
import axios from "axios";

function Header() {
  const [projectCategory, setProjectCategory] = useState([]);

  useEffect(() => {
    axios
      .get("getProjectCategoryAndSubCategory")
      .then((res) => setProjectCategory(res.data));
  }, []);


  const [productCategory, setProductCategory] = useState([]);

  useEffect(() => {
    axios
      .get("getProductCategory")
      .then((res) => setProductCategory(res.data));
   
  }, []);







  // const handleClickScroll = () => {
  //   const element = document.getElementById("1");
  //   if (element) {
  //     // 👇 Will scroll smoothly to the top of the next section
  //     element.scrollIntoView({ behavior: "smooth" });
  //   }
  // };

  return (
    <>
      <div className="mainheader">
        <div className="top-nav">
          <ul style={{ float: "left", width: "50%" }}>
            <li>
              <span
                className="fa fa-phone"
                style={{ marginRight: "5px" }}
              ></span>
              91-22-27622 754/59
            </li>
            <li>
              <span
                className="fa fa-envelope"
                style={{ marginRight: "5px" }}
              ></span>
              sales@samarthsec.com
            </li>
          </ul>
          <ul style={{ float: "right", width: "30%", listStyleType: "inline" }}>
            <li style={{ borderRight: "none" }}>
              <Link to="https://www.facebook.com/samarthsec" target="_blank">
                <i className="fa fa-facebook"></i>
              </Link>
            </li>
            <li style={{ borderRight: "none" }}>
              <Link to="https://twitter.com/SamarthSec" target="_blank">
                <i className="fa fa-twitter"></i>
              </Link>
            </li>
            <li style={{ borderRight: "none" }}>
              <Link
                to="https://www.linkedin.com/company/samarthsec"
                target="_blank"
              >
                <i className="fa fa-linkedin"></i>
              </Link>
            </li>
            <li style={{ borderRight: "none" }}>
              <Link
                to="https://instagram.com/samarthsecuritysystems?igshid=MzRlODBiNWFlZA=="
                target="_blank"
              >
                <i className="fa fa-instagram"></i>
              </Link>
            </li>
          </ul>
        </div>
      </div>

      <div className="menubar">
        <Link to="/" id="logo">
          <img src={Logo} alt="SAMARTH SECURITY SYSTEMS" />
        </Link>
        <nav>
          <label htmlFor="drop" className="toggle">
            &#8801; Menu
          </label>
          <input type="checkbox" id="drop" />
          <ul className="menu">
            <li>
              <Link to="/"> Home </Link>
            </li>
            <li>
              <label htmlFor="drop-7" className="toggle">
                About Us +
              </label>
              <Link to="#">About Us</Link>
              <input type="checkbox" id="drop-7" />
              <ul>
                <li>
                  <Link to="/CompanyProfile">Company Profile</Link>
                </li>
                <li>
                  <Link to="/LeadByExample">Leading By Example</Link>
                </li>
                <li>
                  <Link to="/Testimonials">Testimonials</Link>
                </li>
              </ul>
            </li>

            <li>
              <label htmlFor="drop-8" className="toggle">
                Products +
              </label>
              <Link to="#">Products</Link>
              <input type="checkbox" id="drop-8" />
              <ul>
               {productCategory.map((item2) => (
              <li key={item2.id}>
              <Link to={"/Products/" + item2.id}>
                {item2.product_category}
              </Link>
            </li>
             ))} 
              </ul>
            </li>

         

            <li>
              <label htmlFor="drop-9" className="toggle">
              Prime Project's +
              </label>
              <Link to="#">Prime Project's</Link>
              <input type="checkbox" id="drop-9" />
              <ul>
                {projectCategory.map((item) => (
                  <li key={item.id}>
                    <label htmlFor={"drop-" + item.id} className="toggle">
                      {item.category} +
                    </label>
                    <Link to="#">{item.category}</Link>
                    <input type="checkbox" id={"drop-" + item.id} />
                    <ul>
                      {item.sub_category.map((sub_category) => (
                        <li key={sub_category.id}>
                          <Link to={"/OurProjects/" + sub_category.id}>
                            {sub_category.sub_category}
                          </Link>
                        </li>
                      ))}
                    </ul>
                  </li>
                ))}
              </ul>
            </li>

            <li>
              <Link to="/OurClients">Clients</Link>
            </li>
            <li>
              <Link to="/Services">Services</Link>
            </li>
            <li>
              <Link to="/OurNews">News</Link>
            </li>
            <li>
              <Link to="/ContactUs">Contact Us</Link>
            </li>
          </ul>
        </nav>
      </div>
    </>
  );
}

export default Header;
