import { useEffect, useState } from "react"
import React from 'react'
import Footer from './Footer'
import Header from "./Header"
import axios from "axios"

function Testimonials() {

    const [data, setData] = useState([])

    useEffect(() => {
         axios.get("getTestimonials")
            .then((res) => setData(res.data))
    }, [])


    return (
        <div className="bodyWrapper">
            <Header />
            <section className="bannerImg">
                <img src="images/testimonials/banner.jpg" alt="testimonials" />
            </section>
            <section className="pageContent">
                <div className="wrap">
                    <h1 className="pageHeading">Testimonials</h1>
                    <div className="content">
                        <ul className="testimonials">
                            {
                                data.map((item) =>

                                    <li key={item.id}>
                                        <img src={"https://api.samarthsec.com/public/admin/images/testimonials/" + item.testimonials_image }/>
                                    </li>

                                )
                            }



                        </ul>
                    </div>
                </div>
            </section>
            <Footer/>
        </div >
    )
}

export default Testimonials
