import React from "react";
import CovidBanner from "../assets/images/products/banner.jpg";
import Header from "./Header";
import Footer from "./Footer";
import { useEffect, useState } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import { Button, Modal } from "react-bootstrap";

function Products(props) {
  let { id } = useParams();


  const [data, setData] = useState([]);

  useEffect(() => {
    const response = axios.get("getProductDetails", {
      params: { id: id },
    });
    response.then((res) => {
      setData(res.data);
    });
    //   console.log("result", data);
  }, [id]);



  const [show, setShow] = useState(false);
  const [Title, setTitle] = useState(true);
  const [Img, setImg] = useState(true);
  const [Info, setInfo] = useState(true);

  const handleClose = () => setShow(false);

  function  handleClick(title,img,info) {
    
    setShow(true);
    setTitle(title);
    setImg(img);
    setInfo(info);
   
  }




  return (
    <>
      <div className="bodyWrapper">



      {/* <Button variant="primary" onClick={handleShow}>
        Launch demo modal
      </Button> */}

      <Modal style={{ textAlign: "center" }} show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title> <h2>{Title}</h2></Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <img src= {Img} alt="" style={{ width:"50%" }} />
         
        <br/><br/>
        <p style={{ fontSize: "16px", textAlign: "center" }}>
        {Info}
        </p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" style={{ backgroundColor:"red", color:"#fff", padding:"5px" }} onClick={handleClose}>
            Close
          </Button>
         
        </Modal.Footer>
      </Modal>


        <Header />

        <section className="bannerImg">
          <img src={CovidBanner} alt="Client banner" />
        </section>
        <div className="overlay"></div>

        <section className="pageContent">
          <div className="wrap">
            <h1 className="pageHeading">OUR PRODUCTS</h1>
            <div className="content productsSec">
              {data.map((item) => (
                <div className="productGallery" key={item.id} id={item.id}>
                  <h2 className="headStyle">{item.product_category}</h2>
                  {item.description ? (
                    <div className="content">
                      <p>{item.description}</p>
                    </div>
                  ) : (
                    <span></span>
                  )}
                  <div className="productsWrap">
                    <ul>
                      {item.products.map((products) => (
                        <>
                          <li key={products.id}>
                            <div className="imgsection">
                              <img
                                style={{ width: "172px", height: "265px" }}
                                src={
                                  "https://api.samarthsec.com/public/admin/images/product/" +
                                  products.product_image
                                }
                                alt={products.title}
                                onClick={() =>
                                  handleClick(
                                    products.title,
                                    "https://api.samarthsec.com/public/admin/images/product/" +
                                      products.product_image,
                                    products.description
                                  )
                                }
                              />
                            </div>
                            {products.pdf ? (
                              <>
                              <span
                                className="btn-1"
                                onClick={() =>
                                  handleClick(
                                    products.title,
                                    "https://api.samarthsec.com/public/admin/images/product/" +
                                      products.product_image,
                                    products.description
                                  )
                                }
                              >
                                {products.short_title}  
                                <a
                                  href={
                                    "https://api.samarthsec.com/public/admin/images/product/" +
                                    products.pdf
                                  }
                                  target="_blank"
                                  className="sideArrow"
                                ></a>
                              </span>
                              
                              {products.gem_url? (
                                <div style={{ textAlign:"center" }}>
                                  <a href={products.gem_url} style={{ textDecoration:"none" }} target="_blank">
                               GEM Link
                                </a>
                                </div>
                                 ) : (
                                  <div style={{ textAlign:"center" }}>
                                  <a  style={{ textDecoration:"none" }}>
                                
                                </a>
                                </div>
                                
                                ) }
                                </>
                            ) : (
                              <>
                              <span>
                                {" "}
                                <a
                                  className="btn-1"
                                  onClick={() =>
                                    handleClick(
                                      products.title,
                                      "https://api.samarthsec.com/public/admin/images/product/" +
                                        products.product_image,
                                      products.description
                                    )
                                  }
                                >
                                  {products.short_title} 
                                </a>

                              
                              </span>

                              {products.gem_url? (
                              <div style={{ textAlign:"center" }}>
                                <a href={products.gem_url} style={{ textDecoration:"none" }} target="_blank">
                             GEM Link
                              </a>
                              </div>
                               ) : (
                                <div style={{ textAlign:"center" }}>
                                <a  style={{ textDecoration:"none" }}>
                              
                              </a>
                              </div>
                              ) }

                                   
                                    </>
                            )}
                          </li>
                        </>
                      ))}
                    </ul>
                    {item.url ? (
                      <a className="readMore" href={item.url} target="_blank">
                        {" "}
                        View PDF
                      </a>
                    ) : (
                      <span></span>
                    )}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </section>

        <Footer />
      </div>
    </>
  );
}

export default Products;
