import React, {useState, useEffect} from 'react';
import axios from 'axios';



// import './style.css';

const ReactSliderBasic = ()=>{
    const [currentIndex, setCurrentIndex] = useState(0);
    const [intervalId, setIntervalId] = useState(null);

    const [data, setData] = useState([])

    useEffect(() => {
      axios.get("getSliders")
      .then((res) => setData(res.data));
        }, [])

    const item = [];
    for (let i = 0; i < data.length; i++) {
      const items = data[i];
      item.push({id:items.id, content: <div><img src={"https://api.samarthsec.com/public/admin/images/slider/" + items.slider_image} alt={items.description} /></div>},);
    }
 
 
    useEffect(()=>{
        const id = setInterval(()=>{
            setCurrentIndex((currentIndex + 1) % item.length);
            // console.log('slidre call here automaticly');
        }, 3000);

        setIntervalId(id);
        // console.log('yes useEffect call here _1')
        return ()=> clearInterval(id);

    }, [currentIndex, item.length]);

    // const handlerPrev = ()=>{
    //     clearInterval(intervalId);
    //     setCurrentIndex((currentIndex - 1) % item.length);
    // }

    // const handlerNext = ()=>{
    //     clearInterval(intervalId);
    //     setCurrentIndex((currentIndex + 1) % item.length);
    // }
    const handlerClick = (itemIndex)=>{
        clearInterval(intervalId);
        setCurrentIndex(itemIndex);
    }

    return (
        <>
            <div className='slider'>
                {item.map((itemContent, index)=>{
                        const isActive = index === currentIndex;
                        const className = `slider-item ${isActive ? 'active': ''}`
                        return (
                            <div 
                                className={className} 
                                key={itemContent.id}

                                >
                                {
                                 itemContent.content   
                                }
                            </div>
                        )
                    })
                }
                <div className='slider-controls'>
                    {/* <button onClick={handlerPrev}>
                        Prev
                    </button> */}
                    <div className='slider-indicator'>
                        {
                            item.map((_, index)=>{
                                return (<div style={{ margin:"10px 0px" }}><span key={index} 
                                    onClick={()=>{handlerClick(index)}}
                                    className={`slider-indicator-item ${index === currentIndex ? 'active' : ''}`}
                                    >
                                    {/* {index+1} */}
                                </span></div>)
                            })
                        }
                    </div>
                    {/* <button onClick={handlerNext}>
                        Next
                    </button> */}
                </div>
            </div>
        </>
    )
}

export default ReactSliderBasic;