import axios from "axios";
import React from "react";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

function HomeProjects() {
  const [data, setData] = useState([]);

  useEffect(() => {
    axios.get("getProjects").then((res) => setData(res.data));
  }, []);

  return (
    <>
      <div className="wrap">
        <h1 className="sectionTitle">PROJECTS </h1>
        <div className="content">
          <p>
            At SSSIPL, we are committed to deliver quality security and
            surveillance solutions with prompt service and training support.
            Whatever be the size of your project, we are equipped to deliver
            custom-made security and surveillance solutions to meet your
            priorities. Look no further. Give your organisation the SSSIPL edge.
            Be rest assured of peace of mind. Always!
          </p>
        </div>
        <div className="projList">
          <ul>
            {data.map((item) => (
              <>
                {item.for_home == "Yes" && (
                  <li key={item.id} style={{ minHeight:"260px" }}>
                    <Link
                      key={item.id}
                      to={"/OurProjects/" + item.sub_category_id}
                    >
                            <img
                              src={
                                "https://api.samarthsec.com/public/admin/images/project/" +
                                item.project_home_image
                              } alt={item.title}/>
                         
                    </Link>
                    <p>{item.title} </p>
                  </li>
                )}
              </>
            ))}
          </ul>
        </div>
      </div>
    </>
  );
}

export default HomeProjects;
