import React from 'react'
import Profile from '../assets/images/about-us/company-profile.jpg'
import Header from "./Header";
import Footer from "./Footer";

function CompanyProfile() {
    return (
        <div className="bodyWrapper">
            <Header/>
            <section className="bannerImg">
                <img src={Profile} alt="SAMARTH SECURITY SYSTEMS" />
            </section>
            <section className="pageContent specialCase">
                <div className="wrap">
                    <h1 className="pageHeading">Company Profile</h1>
                    <div className="content">
                        <p>Creating a secure environment is a complex task. It requires the inclination and intellect to rise above challenges that get steeper with each coming day. It involves technologies and competencies that are ahead of time and reliable to the core. Over here negligence has no room and neither is there a scope for uncertainties. Every move has to be tracked and every step taken has to reflect high accountability. SAMARTH SECURITY SYSTEMS (INDIA) PVT. LTD. (SSSIPL) is a sum of all of the above. Specialised in manufacturing, supplying, testing, installation and commissioning of hand held & Walk-through metal detectors and high-tech electronic security & surveillance equipment, SSSIPL is an innovation led company that aims to raise the bar in security and surveillance with each coming day.­­­ Our core values-truth, integrity, reliability and hard work continue to inspire us to come up with innovative answers to today’s complex security needs. Everyday, our multi-disciplinary team of experts consciously works towards one common goal – to make your  world  a  safer  place  to  live. </p>
                        <p>At SSSIPL, we are equipped to provide security solutions to projects of every size and scale. Our sprawling manufacturing plant, equipped with the latest tech-enabled machines including calibration system and high-end technical facilities enables us to walk the talk with unmatched credibility. We have also deployed high quality design software to conceptualise and create world standard products for our clients. Additionally, our in-house R&D division and holistic testing facilities ensure that every SSSIPL product  adheres  to  the  highest  standards  of  quality  and  performance.  “At Samarth Security Systems, we take pride in our role as pioneers in the field of metal detection technology. Our state-of-the-art metal detectors are meticulously designed and manufactured to ensure precision, reliability, and accuracy. Whether it's enhancing security at airports, industrial facilities, or public venues, our metal detectors play a pivotal role in safeguarding people and assets.”</p>
                        <p>As system integrators, we excel in seamlessly integrating security systems and technologies to create holistic security solutions. Our expertise extends beyond metal detectors to encompass a wide spectrum of security infrastructure, including access control, surveillance, and public alarm systems. We collaborate closely with our clients to design and implement tailored solutions that meet their unique security needs.</p>
                        <p>Our proven expertise in the security and surveillance domain has made us the partner of choice for govt. & semi-govt. organisations, corporate business houses, educational institutes, PSUs, military, para-military, police, nationalised & multinational banks, air ports & airline companies, refineries & oil depots, docks & ports, ordinance factories, five star hotels, foreign consulates and private sector companies. For us, customer satisfaction comes above everything else. Our passion to exceed customer expectations has helped us garner over 4000 satisfied customers belonging to various domains. The list continues to increase  as  we  always  follow  a  customer  first  policy, even  if  that  means  earning lesser profits.</p>
                    </div>

                    <h1 className="pageHeading">Vision</h1>
                    <div className="content">
                        <p>Our vision at Samarth Security Systems is to empower organizations and individuals with the peace of mind that comes from robust security. We are committed to staying ahead of the curve in security innovation and offering turnkey solutions that provide a comprehensive shield against threats.</p>
                    </div>
                    <h1 className="pageHeading">Mission</h1>
                    <div className="content">
                        <p>To provide high quality safety solutions and innovate better ways to safeguard the nation.</p>
                    </div>

                    <h1 className="pageHeading">Proven Quality and Reliability</h1>
                    <div className="content">
                        <p>For over ten years, Samarth’s Walk Through Metal Detectors & HHMD’s have reliably protected lives and valuable properties all over India.
                        </p>
                    </div>

                    <h1 className="pageHeading">Certifications</h1>
                    <div className="content">
                        <p>We comply to  International & National statutory norms (Electrical & Health Safety, EMC) and for quality systems are complying with ISO 9001:2015 and MHA Guideline
                        </p>
                    </div>

                    <h1 className="pageHeading">Our eye on the security and the future!</h1>
                    <div className="content">
                        <p>At Samarth Security Systems, we are constantly improving our products to meet the ever changing and increasing market demand for high quality & dependable security threat detection.
                        </p>
                    </div>

                    <h1 className="pageHeading">Designed and Manufactured in India
                    </h1>
                    <div className="content">
                        <p>With the support from the Government of India, “Make in India” policy initiative, Samarth Security has pioneered the Multiple-Zone (4,6,8,9,12 zones) Metal Detector’s with Text over Video overlay, a first in the world walk-through metal detector to accurately pinpoint the location of weapons/contraband with video evidence of the person entering through the DFMD, a very useful feature for post mortem analysis & investigation.</p>
                    </div>

                    <h1 className="pageHeading">Exports</h1>
                    <div className="content">
                        <p>We have exported our HHMD’s & DFMD’s to Africa, Middle East & South East Asia.</p>
                    </div>

                    {/* <h1 className="pageHeading">Quality Policy</h1>
                    <div className="content">
                        <p>We at Samarth security systems, with focus on customer satisfaction, are commited to satisfy applicable requirements and to achive excellence in quality of our products and services.<br />
                            <a href="images/products/pdf/Quality_PolicyN.pdf" target="_blank" className="sideArrow">Click here for Quality Policy</a>
                        </p>
                    </div> */}
                </div>
            </section>
            <Footer/>
        </div>
    )
}

export default CompanyProfile
