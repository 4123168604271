import axios from "axios";
import React from "react";
import { useEffect, useState } from "react";

function HomeClients() {


  const [data, setData] = useState([]);

  useEffect(() => {
    axios.get("getClients").then((res) => setData(res.data));
  }, []);

  return (
    <div>
      <section
        className="pageContent"
        style={{ padding: "30px 0 20px", background: "#f1f1f1" }}
      >
        <div className="wrap" style={{ width: "95%" }}>
          <h1 className="sectionTitle">Clients </h1>
          <div className="content">
            <marquee
              id="scroll_news"
              behavior="scroll"
              direction="left"
              scrollamount="4"
              scrolldelay="2"
             
              style={{
                padding: "5px",
                backgroundColor: "#f1f1f1",
                border: "solid 2px #F1F1F1",
              }}
            >
              <div>
                {data.map((item) => (
                
                 
                    <a style={{ width: "250px" }} key={item.id}>
                         {item.for_home == "Yes"  &&
                      <img
                        src={
                          "https://api.samarthsec.com/public/admin/images/clients/" +
                          item.clients_image
                        }
                        style={{
                          padding: "5px 15px",
                          width: "auto",
                          maxWidth: "250px",
                          maxHeight: "150px",
                        }}
                        alt={item.client_category}
                      />
                    }
                      </a>
                   
                 
                ))}
              </div>
            </marquee>
          </div>
        </div>
      </section>
    </div>
  );
}

export default HomeClients;
